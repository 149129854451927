.op-input,
.op-password-input,
.op-search-input {
  box-shadow: none !important;

  &:hover:not(:focus) {
    border-color: var(--colorTextLabel) !important;
  }

  &:focus {
    border-color: var(--colorPrimaryHover) !important;
  }

  // Need this for unique password input setup from antd
  &.ant-input-affix-wrapper-focused:hover {
    border-color: var(--colorPrimaryHover) !important;
  }

  // Apply disabled styles with higher specificity
  &.ant-input[disabled],
  .ant-input-disabled {
    color: var(--colorTextDisabled);
    /* Test color */
  }
}