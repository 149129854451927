.video-region {
  pointer-events: none;
  position: absolute;
  background-color: rgba(0, 255, 255, 0.2);

  &__privacy-mask {
    background-color: black;
  }

  &__detection-window {
    background-color: rgba(0, 255, 0, 0.2);
  }
}
