@use 'scss/common';

.help-menu {
  &__button {
    color: var(--colorTextBase) !important;

    &.ant-dropdown-open {
      background-color: var(--colorPrimary) !important;
      color: white !important;
    }

    &:hover {
      opacity: 60%;
    }
  }
}

.help-menu-overlay {
  .ant-dropdown-menu {
    padding-top: 9px;
    padding-bottom: 9px;
    // @include common.utn-dropdown-overlay;
  }

  .ant-dropdown-menu-item {
    border-radius: 0 !important;
    margin: 0 -4px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;

    &:hover {
      background-color: var(--colorUtnMenuItemHover) !important;
    }
  }

  .ant-dropdown-arrow {
    top: 1px !important;
    right: 12px !important;

    &::before {
      background: var(--colorBgContainer) !important;
    }

    &::after {
      border: var(--utnMenuBorder) !important;
    }
  }
}
