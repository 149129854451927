.reset-password-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    padding-top: 40px;

    .reset-password-content {
        width: 100%;
        max-width: 900px;
        background-color: #ffffff;
        padding: 40px;
        border-radius: 8px;
        border: 1px solid #c4c4c4;

        .logo {
            display: block;
            margin: 0 auto 30px auto;
            max-width: 250px;
        }

        .reset-password-header {
            text-align: left;
            margin-bottom: 20px;

            p {
                font-size: 14px;
                margin-bottom: 10px;
            }

            strong {
                font-weight: bold;
            }
        }

        .password-instructions {
            font-size: 14px;
        }

        .entry-buttons {
            display: flex;
            flex-direction: column;
        }

        .message-box {
            text-align: center;
            color: #2f2f2f;
            padding: 15px 20px;
            background-color: #eaeaea;
            border-radius: 2px;
            font-size: 14px;
        }

        .success-message {
            text-align: center;
            color: #000000;
            padding: 15px 20px;
            font-size: 14px;
        }

        .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            font-size: 14px;
        }
    }

    .reset-password-footer {
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
        color: #000000;
        width: 100%;
    }

    .ant-btn-primary {
        width: 100%;
    }

    .ant-form-item {
        margin-bottom: 15px;
    }
}