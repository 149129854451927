@use '/src/scss/common';

.op-table-core {
  border: common.toRem(1) solid var(--colorBorderSecondary);
  border-radius: var(--borderRadius);
  overflow: hidden;

  .ant-table-title {
    padding: 0 !important;
    border-bottom: common.toRem(1) solid var(--colorBorderSecondary) !important;
  }

  .ant-table-header {
    border-radius: 0 !important;
  }

  .ant-table-thead > tr > .ant-table-cell {
    background-color: var(--colorFillQuaternary);
  }

  .ant-table-footer {
    background-color: var(--colorFillPrimary);
    border-top: 1px solid var(--colorBorderSecondary) !important;
    padding: 0 !important;

    .ant-select-selector {
      background-color: transparent;
    }
  }

  table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0 !important;
  }

  // Add '--' to any empty cells in the table body
  tbody td:empty:before {
    content: '--';
  }

  // Removes unnecessary vertical scrollbar
  .ant-table-body {
    overflow: auto !important;
  }

  // Adds padding to table cells
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding-left: common.toRem(16) !important;
  }

  // Fixes issue of unnecessary horizontal scrollbar due to extra padding on table cells
  .ant-table.ant-table-middle .ant-table-expanded-row-fixed {
    margin: common.toRem(-12) common.toRem(-16) !important;
  }

  // Remove borders on td of the last row of the table
  .ant-table-tbody > tr:last-of-type > td {
    border: none !important;
  }

  .ant-table-row:not(:hover, .ant-table-row-selected) {
    .ant-table-column-sort {
      background-color: unset !important;
    }
  }
}
