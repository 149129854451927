@use '/src/scss/common';

.table-pagination {
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;

    .op-button {
      border: none !important;
      border-radius: 0 !important;
      border-right: 1px solid var(--colorBorderSecondary) !important;

      &:disabled {
        opacity: 0.8;
      }
    }
  }

  &__prev-page,
  &__next-page {
    transform: rotate(90deg);
  }

  &__text {
    padding: 0 common.toRem(16);
    height: 48px;
    line-height: 48px;
    color: var(--colorTextLabel);
  }

  &__page-size-container {
    height: 48px;
    border-left: 1px solid var(--colorBorderSecondary);
    // Primary hover color
    &:hover > .op-select > .ant-select-selector > .ant-select-selection-item {
      color: var(--colorPrimaryHover) !important;
    }

    // Primary active color
    & > .ant-select-open > .ant-select-selector > .ant-select-selection-item {
      color: var(--colorPrimaryActive) !important;
    }

    .ant-select-selector {
      border: none !important;
      border-radius: 0 !important;
      padding: 0 16px !important;
    }

    .ant-select-arrow {
      right: 16px !important;
    }
  }
}
