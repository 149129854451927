@use '../../../../../../scss/common';

.op-form-data-fetch-transfer {
  /** Making this a little bigger that checkbox which is 18px to prevent 
  issue where clicking on bottom of checkbox doesn't check/uncheck */
  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item
    input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
}
