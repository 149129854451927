body {
  background-color: var(--colorBgLayout);
  background: var(--colorBgLayout);
  -ms-overflow-style: none;
}

ul,
ol {
  padding-inline-start: 16px;
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // TODO: Need to update input background colors when autofilled
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: var(--colorTextBase);
  background-color: inherit !important;
}

footer {
  color: var(--colorTextBase) !important;
  background-color: transparent !important;
}

//? Uncomment this to hide scroll bars
// ::-webkit-scrollbar {
//   display: none;
// }

// Change the color of the intersection when the vertical and horizontal scroll bars meet
::-webkit-scrollbar-corner {
  background-color: var(--colorFillSecondary);
}

// Change the color of the scrollbar track
::-webkit-scrollbar-track {
  background-color: var(--colorFillSecondary) !important;
}

/* Change the color of the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: var(--colorFill) !important;
}

.live-button,
.sliding-toggle-label,
nav ul li.active a,
nav ul li a:hover,
.sub-tables-data-label {
  font-weight: 500;
}

/* Icons */
i.icon.delete::before,
i.icon.close::before {
  content: '\2715';
}

/* Prevents LastPass from affecting anything but our password inputs */
[data-lastpass-icon-root]:not(.op-password-input [data-lastpass-icon-root]) {
  display: none !important;
}

/* So you can see the text when highlighting it */
:root.dark {
  ::selection {
    color: white;
  }
}
