@use '/src/scss/common';

.op-select {
  color: var(--colorTextBase);

  &:hover {
    .ant-select-selector {
      border-color: var(--colorTextLabel) !important;
    }
  }

  &:focus {
    .ant-select-selector {
      border-color: var(--colorPrimaryHover);
    }
  }

  &__tag {
    border-radius: 8px;
    line-height: 24px;
    margin-inline-end: 4px;

    &--error {
      background-color: var(--colorErrorBg);
      color: var(--colorErrorText);
    }
  }

  .ant-select-selector {
    box-shadow: none !important;
    padding: 0px 4px;
  }

  .ant-select-selector:after {
    margin: 1px 0;
  }

  .ant-select-focused {
    border-color: transparent !important;
  }

  // For multiple rows of tags
  .ant-select-selection-overflow-item>span {
    line-height: 30px;
  }

  // Override Ant Design's disabled styles
  &.ant-select-disabled {
    .ant-select-selector {
      color: var(--colorTextDisabled) !important; // Set disabled text color
    }
  }
}

.ant-select-item-option-selected {
  font-weight: 500;
}