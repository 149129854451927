@use '/src/scss/common';

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: common.toRem(16); // For mobile

  &__label {
    padding: common.toRem(0) common.toRem(16);
    height: common.toRem(48);
    display: flex;
    align-items: center;
    color: var(--colorTextLabel);
    text-transform: uppercase;
    font-weight: 500;
  }

  &__table-actions {
    display: flex;

    .op-button {
      border: none !important;
      border-left: common.toRem(1) solid var(--colorBorderSecondary) !important;
      border-radius: common.toRem(0) !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
}
