@use '/src/scss/common';

.select-filter {
  width: 100%;

  // Matches Antd Menu styles
  &__radio-list {
    background: var(--colorBgElevated);
    padding: common.toRem(12);
    border-radius: var(--borderRadius);
    box-shadow:
      0 6px 16px 0 rgb(0 0 0 / 8%),
      0 3px 6px -4px rgb(0 0 0 / 12%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }
}
