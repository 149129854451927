$border-radius: 12px;
$border-radius--rounded: 100px;

$base-font-family: 'Graphik', Lato, 'Helvetica Neue', Arial, Helvetica,
  sans-serif;
$base-font-weight: 400;
$base-font-value: 14;

$heading-font-sizes: (
  // 20px
  'h1': 1.42857143rem,
  // 18px
  'h2': 1.28571429rem,
  // 16px
  'h3': 1.14285714rem,
  // 14px
  'h4': 1rem,
  // 12px
  'h5': 0.85714286rem,
  // 10px
  'h6': 0.71428571rem
);

$heading-line-heights: (
  'h1': 125%,
  'h2': 125%,
  'h3': 125%,
  'h4': 150%,
  'h5': 125%,
  'h6': 125%,
);

$body-font-sizes: (
  // stats seems like a loner (40px)
  stats: 2.85714286rem,
  // 16px
  large: 1.14285714rem,
  // 14px
  regular: 1rem,
  // 12px
  small: 0.85714286rem,
  // 10px
  tiny: 0.71428571rem
);

$body-line-heights: (
  stats: 1.2857142857%,
  large: 1.5,
  regular: 1.2857142857,
  small: 1.5,
  tiny: 1.5,
);

$form-font-sizes: (
  // 14px
  label: 1rem,
  // 14px
  input: 1rem,
  // 12px
  error: 0.85714286rem,
  // 12px
  tooltip: 0.85714286rem
);

$form-line-heights: (
  // 17px
  label: 1.21428571rem,
  // 19px
  input: 1.35714286rem,
  // 15px
  error: 1.07142857rem,
  // 17px
  tooltip: 1.21428571rem
);
