.op-button {
  font-weight: 500;

  // These shouldn't be needed but content in not aligning correctly without it
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .ant-btn-icon {
    fill: var(--colorTextBase);
  }
}
