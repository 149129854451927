.column-header {
  margin-right: 4px;
  font-weight: 400;

  &__title {
    color: var(--colorTextLabel);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
  }
}
