.custom-modal .ant-modal-content {
    padding: 0 !important;
    border-radius: 14px;
    /* Preserve border radius */
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}