@use '../common';

body {
  transition: color 0.3s;
  font-feature-settings: 'liga', 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: var(--colorTextBase) !important;
  font-family: common.$base-font-family;
  font-weight: common.$base-font-weight;
  font-size: var(--baseFontSize);
}

.text--small {
  @include common.get-body-font-styles('small');
}

.text--tiny {
  @include common.get-body-font-styles('tiny');
}

.text--stat {
  @include common.get-body-font-styles('stats');
}

p {
  @include common.get-body-font-styles('regular');
  margin: 0;
  font-family: common.$base-font-family;
}
// span {
//   font-size: 14px;
//   line-height: inherit;
//   margin: 0;
//   font-family: common.$base-font-family;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: common.$base-font-family;
  font-weight: 500;
  color: var(--colorTextBase);
}

h1 {
  @include common.get-header-font-styles('h1');
}

h2 {
  @include common.get-header-font-styles('h2');
}

h3 {
  @include common.get-header-font-styles('h3');
}

h4 {
  @include common.get-header-font-styles('h4');
}

h5 {
  @include common.get-header-font-styles('h5');
}

h6 {
  @include common.get-header-font-styles('h6');
}

input {
  font-size: 1rem;
  // line-height: 1.35714286rem;
}
