@use 'scss/common';

:root {
  --colorUtnButton: #0b2d6014;
  --colorUtnMenuItemHover: rgba(0,
      110,
      215,
      24%); // 24% opacity of colorPrimary
  --colorUtnLockdownCardBg: rgba(233, 238, 245, 1);
  --colorUtnAppSwitcherBg: rgba(233, 238, 245, 1);
}

:root.dark {
  --colorUtnButton: #5a74a63d;
  --colorUtnMenuItemHover: rgba(0,
      110,
      215,
      24%); // 24% opacity of colorPrimary
  --colorUtnLockdownCardBg: rgba(35, 46, 69, 1);
  --colorUtnAppSwitcherBg: rgba(0, 0, 0, 0.16);
}

.op-nav-menu {
  background-color: var(--colorHeaderBg) !important;
  /* Ensure consistent background */

  .ant-menu-item {
    background-color: var(--colorHeaderBg) !important;
    /* Ensure menu items have the same background */
    color: var(--colorTextBase);

    /* Ensure text remains visible */
    // height: common.toRem(62) !important; /* Reduced height for smaller menu items */
    // padding: 4px 0 !important; 
    &-icon {
      width: 18px;
      height: 18px;
    }
  }

  &__primary-menu {
    background-color: transparent;
    border-inline-end: none !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colorHeaderBg) !important;

    &-item {
      overflow: visible !important; // This allows the after pseudo-element to extend beyond the bounds of the menu item
      height: common.toRem(64) !important;
      width: common.toRem(64) !important;
      border-radius: 16px !important;
      margin: 0 !important;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: common.toRem(-16);
        /* Extend 16px to the right */
        width: calc(100% + 16px);
        height: 100%;
      }

      &:hover,
      &.hovered,
      &--highlighted {
        background-color: var(--colorUtnMenuItemHover) !important;
      }

      &.ant-menu-item-selected {
        color: #fff !important;
        background-color: var(--colorPrimary) !important;
        font-weight: 800;
      }

      .ant-menu-title-content {
        margin: 4px 0 0 !important;
        font-size: common.toRem(11);
        text-align: center;
        line-height: common.toRem(18);
        user-select: none;
      }
    }
  }

  &__drawer-root {
    position: absolute;
    left: 104px;
    z-index: 1;
  }

  &__drawer {
    background-color: var(--colorHeaderBg) !important;
    border-left: 1px solid var(--colorNavBorder);
    border-right: 1px solid var(--colorNavBorder);
    box-shadow: var(--navBoxShadow);

    .ant-drawer-body {
      display: flex;
      padding: 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      padding: 0 common.toRem(16);
      border: none !important;
    }

    &-title {
      font-size: common.toRem(24);
      line-height: common.toRem(32);
      font-weight: 800;
      margin-bottom: 24px;
      padding: 48px 16px 0;
    }

    .ant-menu-item-group {
      margin-bottom: common.toRem(24);
    }

    .ant-menu-item-group-title {
      color: rgb(from var(--colorTextBase) r g b / 40%) !important;
      text-transform: uppercase;
      font-size: common.toRem(10);
      line-height: common.toRem(40);
      padding: 0 common.toRem(16);
      pointer-events: none;
      font-weight: 800;
    }

    &-menu {
      border-inline-end: none !important;
      background-color: transparent;
      flex: 1;
      // @include common.create-custom-scroll-shadow();

      .ant-menu-item {
        border-radius: 12px !important;
        padding-inline: 16px !important;
        margin: 0 !important;
        width: 100%;
        height: common.toRem(40);
        line-height: common.toRem(40);

        display: flex !important; // Disables ellipsis on text
        transition: background 0s; // Fade out can't sync with transition

        .ant-menu-title-content {
          overflow: hidden;
        }

        .ant-menu-title-content::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
        }

        &:hover {
          background-color: var(--colorUtnMenuItemHover) !important;
          transition: background 0s; // Fade out can't sync with transition

          .ant-menu-title-content::after {
            background: linear-gradient(to right,
                transparent 100%,
                var(--colorUtnMenuItemHover) 80%);
          }
        }

        &.ant-menu-item-selected {
          color: #fff !important;
          background-color: var(--colorPrimary) !important;
          font-weight: 800;

          .ant-menu-title-content::after {
            background: linear-gradient(to right,
                transparent 60%,
                var(--colorPrimary) 80%);
          }
        }

        .ant-menu-title-content::after {
          background: linear-gradient(to right,
              transparent 60%,
              var(--colorHeaderBg) 80%);
        }
      }
    }
  }
}