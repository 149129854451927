.register-completed-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: #ffffff;
    padding: 20px;
}

.register-completed-content {
    width: 100%;
    max-width: 900px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    text-align: center;

    .message-box {
        text-align: center;
        color: #2f2f2f;
        padding: 15px 20px;
        background-color: #eaeaea;
        border-radius: 2px;
        font-size: 14px;
    }


    .register-header p {
        margin-bottom: 20px;
        font-size: 14px;
    }

    .logo {
        display: block;
        margin: 0 auto 20px auto;
        max-width: 250px;
    }

    .qr-code-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
    }

    .qr-code-container svg {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    .download-button-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .download-button {
        width: 100%;
    }

    .wallet-button-container {
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }

    .wallet-button {
        width: 100%;
        max-width: 130px;
        cursor: pointer;
    }
}

.register-footer {
    padding: 20px 0;
    text-align: center;
    font-size: 12px;
    color: rgb(0, 0, 0) !important;
    width: 100%;
    margin-top: 20px;
}