.op-collapse {
  border-color: var(--colorBorderSecondary);
  border-bottom: 0;

  .ant-collapse-content {
    border-top-color: var(--colorBorderSecondary);
  }

  .ant-collapse-item {
    border-bottom-color: var(--colorBorderSecondary);
  }
}
