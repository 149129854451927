@use './variables';

@mixin larger-than($width) {
  @media (min-width: $width) {
    @content;
  }
}

// Optional Utility Mixins
@mixin smaller-than($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin between($minwidth, $maxwidth) {
  @media (min-width: $minwidth) and (max-width: $maxwidth) {
    @content;
  }
}

@mixin get-header-font-styles($size: 'h4', $important: false) {
  @if ($important) {
    font-size: map-get(variables.$heading-font-sizes, $size) !important;
    line-height: map-get(variables.$heading-line-heights, $size) !important;
  } @else {
    font-size: map-get(variables.$heading-font-sizes, $size);
    line-height: map-get(variables.$heading-line-heights, $size);
  }
}

@mixin get-body-font-styles($size: 'regular', $important: false) {
  @if ($important) {
    font-size: map-get(variables.$body-font-sizes, $size) !important;
    line-height: map-get(variables.$body-line-heights, $size) !important;
  } @else {
    font-size: map-get(variables.$body-font-sizes, $size);
    line-height: map-get(variables.$body-line-heights, $size);
  }
}

@mixin get-form-font-styles($size: 'input', $important: false) {
  @if ($important) {
    font-size: map-get(variables.$form-font-sizes, $size) !important;
    line-height: map-get(variables.$form-line-heights, $size) !important;
  } @else {
    font-size: map-get(variables.$form-font-sizes, $size);
    line-height: map-get(variables.$form-line-heights, $size);
  }
}
