// signup.scss
@use 'scss/common';

.auth-wrapper {
    display: flex;
    align-items: stretch;
}

.auth-wrapper__left {
    flex: 1;
    background-image: url('../../../images/split_mobile_checkedin.jpg');
    min-height: 100vh;
    background-size: cover;
    background-position: center;

    &-content {
        padding: 18vh 1.2857142857rem 0 2.5714285714rem;
        align-items: center;

        &-container {
            align-items: center;
        }

        &__text {
            align-items: center;
            padding: 0;
        }

        &__headline {
            max-width: 34rem;
            color: var(--colorWhite) !important;
            font-size: 2.2rem !important;
            font-weight: 500;
            margin-bottom: 40px !important;
        }

        &__body {
            max-width: 30rem;
            color: var(--colorWhite);
            margin-bottom: 2.1428571429rem;
            font-size: 1.1rem;
            line-height: 1.6rem;
        }
    }
}

.auth-wrapper__right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-bg-container);
    color: var(--color-text);
}

.auth-wrapper__right-logo {
    padding-bottom: 40px;
    text-align: center;
}

.signup {
    width: 100%;
}

.signup-success {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}