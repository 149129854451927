@use '../../../../../../scss/common';
.video-region-select {
  &__info {
    margin-bottom: common.toRem(16);
  }

  &__video-wrapper {
    border-radius: var(--borderRadius);
    overflow: auto;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);

    &--read-only {
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__type-choice-button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: common.toRem(16);
    flex-wrap: wrap;
    margin-top: common.toRem(16);
  }
}
