@use '../../../../scss/common';

.op-data-fetch-transfer {
  &__label-wrapper {
    display: flex;
    align-items: center;
    gap: common.toRem(8);
  }

  &__tag {
    height: fit-content;
  }
}
