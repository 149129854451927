.op-time-picker {
  width: 100%;

  & > .ant-picker-input > input {
    color: var(--colorTextBase) !important;
  }

  &:hover {
    border-color: var(--colorTextLabel) !important;
  }

  &:focus {
    border-color: var(--colorPrimaryHover);
  }
}
