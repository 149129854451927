@use '../../../../scss/common';

.op-switch-container {
  display: flex;

  &__label {
    margin-left: common.toRem(8);
  }

  &__tooltip {
    margin-left: common.toRem(4);
  }

  .ant-switch-inner {
    height: common.toRem(24);
    width: common.toRem(44);
  }
}