.register-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    padding: 10px;
    min-height: 100vh;

    .register-content {
        width: 100%;
        max-width: 900px;
        background-color: #ffffff;
        padding: 40px;
        border-radius: 8px;
        border: 1px solid #c4c4c4;

        .logo {
            display: block;
            margin: 0 auto 20px auto;
            max-width: 250px;
        }

        .register-header {
            text-align: center;

        }

        .message-box {
            text-align: center;
            color: #2f2f2f;
            padding: 15px 20px;
            background-color: #eaeaea;
            border-radius: 2px;
            font-size: 14px;
        }

        .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }

        .agreement-link {
            padding-left: 2px;

            &:hover .anticon {
                color: #4ba8ff;
            }
        }

        p {
            font-size: 14px;
            color: var(--colorTextSecondary);
        }

        form {
            display: flex;
            flex-direction: column;

            button {
                width: 100%;
            }
        }

        .custom-checkbox-item {
            margin-top: 4px !important;
            margin-bottom: 0 !important;
        }

        .custom-checkbox {
            margin-top: 4px !important;
            margin-bottom: 0 !important;
        }

        .agreement-error-message {
            color: #de2225;
            margin-top: 8px;
            font-size: 14px;
        }
    }

    .register-footer {
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
        color: rgb(0, 0, 0) !important;
        width: 100%;
    }

    .ant-input[disabled],
    .ant-input-disabled {
        color: rgb(0, 0, 0) !important;
        color: rgb(0, 0, 0) !important;
    }
}