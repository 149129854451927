@use '../../../../scss/common';

.op-form {
  &-item {
    label {
      font-size: 1rem;
      color: var(--colorTextLabel) !important;
    }

    .ant-form-item-explain-error {
      font-size: common.toRem(12);
    }

    .ant-form-item-label {
      padding-bottom: common.toRem(4);
    }
  }

  // &.ant-form-vertical {

  //   // Move required asterisk to the right
  //   label {
  //     &.ant-form-item-required::before {
  //       display: none !important;
  //     }

  //     &.ant-form-item-required::after {
  //       position: relative;
  //       top: common.toRem(-2);
  //       display: inline-block;
  //       margin-inline-end: common.toRem(4);
  //       color: var(--colorErrorText);
  //       font-size: common.toRem(14);
  //       font-family: SimSun, sans-serif;
  //       line-height: 1;
  //       content: '*';
  //       visibility: inherit;
  //     }
  //   }
  // }
}