.guest-pass-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding-top: 40px;


    .guest-pass-content {
        width: 100%;
        max-width: 900px;
        background-color: #fff;
        padding: 40px;
        border-radius: 8px;
        border: 1px solid #c4c4c4;

        .logo {
            display: block;
            margin: 0 auto 30px auto;
            max-width: 200px;
        }

        .guest-pass-header {
            text-align: left;
            margin-bottom: 20px;

            p {
                font-size: 14px;
                margin-bottom: 10px;
            }

            strong {
                font-weight: bold;
            }
        }

        .entry-buttons {
            display: flex;
            flex-direction: column;

            button {
                width: 100%;
                margin-bottom: 10px;
                background-color: rgba(200, 235, 255, 255);

                &:hover {
                    background-color: rgba(200, 235, 255, 255);
                }

                &.opened {
                    background-color: rgba(109, 152, 138, 255);
                    color: white;
                }

                &.failed {
                    background-color: rgb(255, 31, 31);
                    /* Red background for failure */
                    color: white;
                }
            }
        }

        .message-box {
            text-align: center;
            color: #2f2f2f;
            padding: 15px 20px;
            background-color: #eaeaea;
            border-radius: 4px;
            font-size: 14px;
        }

        .expiration-message {
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
            color: #2f2f2f;
        }

        .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            font-size: 14px;
        }
    }

    .guest-pass-footer {
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
        color: rgb(0, 0, 0) !important;
        width: 100%;
    }
}