@use '../../../../scss/common';

.op-section {
  padding: common.toRem(16);
  border-radius: var(--borderRadius);
  border: 1px solid var(--colorBorderSecondary);
  margin-bottom: common.toRem(24);
  display: flex;
  flex-direction: column;

  &__label {
    display: inline-block;
    font-size: common.toRem(12);
    line-height: 100%;
    text-transform: uppercase;
    color: var(--colorTextLabel);
    margin-bottom: common.toRem(24);
    font-weight: 500;
  }

  &__tooltip {
    margin-left: common.toRem(4);
  }

  &__loading-wrapper {
    height: common.toRem(300);
    font-size: common.toRem(36);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
