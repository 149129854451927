.op-tag-input {
  color: var(--colorTextBase);
  width: 100%;

  &__mock-dropdown {
    background: var(--colorBgElevated);
    position: absolute;
    z-index: 1050;
    border-radius: 8px;
    padding: 4px;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow:
      0 6px 16px 0 rgb(0 0 0 / 8%),
      0 3px 6px -4px rgb(0 0 0 / 12%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  &__mock-dropdown-item {
    background: var(--colorBgElevated);
    padding: 7px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
}
