@use 'scss/common';

.auth-wrapper {
    display: flex;
    align-items: stretch;
    min-height: 100vh;

    &__left {
        flex: 1;
        background-image: url('../../images/split_mobile_checkedin.jpg');
        background-size: cover;
        background-position: center;
        display: block;

        &-content {
            padding: 18vh 1.2857142857rem 0 2.5714285714rem;
            align-items: center;

            &-container {
                align-items: center;
            }

            &__text {
                align-items: center;
                padding: 0;
            }

            &__headline {
                max-width: 34rem;
                color: var(--colorWhite) !important;
                font-size: 2.2rem !important;
                font-weight: 500;
                margin-bottom: 40px !important;
            }

            &__body {
                max-width: 30rem;
                color: var(--colorWhite);
                margin-bottom: 2.1428571429rem;
                font-size: 1.1rem;
                line-height: 1.6rem;
            }
        }
    }

    &__right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #121826 !important;
        color: #ffffff !important;
        padding: 2rem;

        &-content {
            width: 100%;
            max-width: 400px;
        }

        &-logo {
            padding-bottom: 40px;
            text-align: center;

            img {
                max-width: 250px;
                height: auto;
            }
        }
    }

    .login {
        width: 100%;

        .ant-form-item {
            margin-bottom: 1rem;
        }
    }

    .button {
        width: 100%;
        margin-top: 0.5rem;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        &__left {
            display: none;
        }

        &__right {
            min-height: 100vh;
            padding: 1rem;
            justify-content: flex-start;
            padding-top: 2rem;

            &-logo img {
                max-width: 220px;
            }
        }

        .version-number {
            position: static;
            margin-top: 2rem;
        }
    }

    @media (max-width: 480px) {
        &__left {
            font-size: 14px;
        }

        .button {
            padding: 1rem;

            &-content {
                padding: 0 15px;
            }

            &-logo img {
                max-width: 240px;
            }
        }
    }
}