.global-search {
  height: 48px;
  border-left: 1px solid var(--colorBorderSecondary);

  &__input {
    // Primary hover color
    &:hover {
      color: var(--colorPrimaryHover) !important;
    }

    // Primary active color
    &.ant-input-affix-wrapper-focused {
      color: var(--colorPrimary) !important;
    }

    border: none !important;
    border-radius: 0 !important;
  }
}
