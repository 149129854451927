@use '../../../../scss/common';

.op-code-editor {
  font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro,
    monospace !important;
  width: 100% !important;
  border: common.toRem(1) solid var(--colorBorderSecondary);
  border-radius: common.toRem(4);
  padding-left: common.toRem(10);
  padding-right: common.toRem(40);

  // Prevent editing the code in the editor when disabled, but still allow scrolling
  &--disabled {
    .ace_scroller {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  // &--error {
  //   border-color: var(--colorErrorText);
  //   background-color: var(--colorErrorBg);
  // }

  /** Ace Editor Changes to prevent table z-index issues */
  //! We Need this - do not delete - if you do delete it: run a portal audit report -> expand json -> scroll down
  .ace_gutter {
    z-index: 1 !important;
  }

  .ace_scrollbar {
    z-index: 1 !important;
  }

  .css-45sl8r-ClearIndicator {
    z-index: 3 !important;
    /* Normal is z-index: 10 */
  }

  // /* This cuts off text so it doesn't overflow */
  // .css-ltvhks {
  //   position: relative !important;
  //   overflow: hidden !important;
  // }
}
