@use '../../../scss/common';

.op-page {
  padding: common.toRem(16);
  padding-left: common.toRem(48);
  padding-right: common.toRem(48);
  overflow: auto;
  height: 100%;
  background-color: var(--colorBgContainer);

  &__header {
    margin-bottom: common.toRem(16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; // 줄 바꿈을 가능하게 함
  }

  &__header-left {
    display: flex;
    flex-direction: column;
  }

  &__title-row {
    display: flex;
    align-items: center;
  }

  &__title {
    padding-right: common.toRem(4);
  }

  &__subtitle {
    font-size: common.toRem(14);
    color: var(--colorTextLabel);
  }

  &__content-right {
    display: flex;
    align-items: center;
  }

  // ✅ 반응형 스타일 (contentRight를 title 아래로 이동)
  @media (max-width: 768px) {
    .op-page__header {
      flex-direction: column;  // 세로 정렬
      align-items: flex-start; // 왼쪽 정렬
    }

    .op-page__content-right {
      margin-top: common.toRem(8); // 제목과 간격 추가
      width: 100%; // 전체 너비 사용
      justify-content: flex-start; // 왼쪽 정렬
    }
  }
}
