@use '/src/scss/common';

.batch-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  position: relative;

  // Remove space above menu and set top border-radius to 0
  &__dropdown-menu {
    margin-top: -7px !important;
    border-radius: 0 0 var(--borderRadius) var(--borderRadius) !important;
  }

  &__select {
    border-right: 1px solid var(--colorBorderSecondary) !important;

    .ant-select-selector {
      padding: 0 common.toRem(20) 0 common.toRem(16) !important;
    }

    .ant-select-arrow {
      right: common.toRem(16) !important;
    }

    & > div {
      border: none !important;
      border-radius: 0 !important;
    }

    // Primary hover color
    &:not(.ant-select-disabled):hover
      > .ant-select-selector
      > .ant-select-selection-item {
      color: var(--colorPrimaryHover) !important;
    }

    // Primary active color
    &.ant-dropdown-open > .ant-select-selector > .ant-select-selection-item {
      color: var(--colorPrimaryActive) !important;
    }

    // Overrides lighter color with antd default when sub menu is open (when top menu is closed with esc)
    // To see the behavior you can also click and then move mouse so that it is no longer hovering the
    // BatchActions component. You will notice the light text color if the override below is not used
    &.ant-select-open:not(.ant-dropdown-open)
      > .ant-select-selector
      > .ant-select-selection-item {
      color: var(--colorTextBase) !important;
    }
  }

  &__text-container {
    width: 200px;
    position: absolute;
    right: 0;
    transform: translateX(100%);
    padding-left: common.toRem(16);
  }

  &__selected-count {
    color: var(--colorTextLabel);
  }

  &__clear-button {
    padding: 0 8px;
  }
}
