@use '../../../../../../scss/common';

.op-form-video-region-select {
  margin-bottom: 0;

  &__skeleton {
    width: 100% !important;
    height: common.toRem(322) !important;
  }

  &__skeleton-icon {
    font-size: common.toRem(40);
    color: var(--colorTextLabel);
  }
}
