@use 'scss/common';

:root {
  --colorNavBorder: rgba(18, 24, 38, 16%);
  --navBoxShadow: 1px 0px 64px 0px rgba(0, 0, 0, 16%);
}

:root.dark {
  --colorNavBorder: rgba(0, 0, 0, 64%);
  --navBoxShadow: 1px 0px 64px 0px rgba(0, 0, 0, 48%);
}

.op-nav {
  z-index: 1000; // Ensure the OpNav is above the content including the drawer
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-right: 1px solid var(--colorNavBorder);

  &__close-button-container {
    height: 56px;
    padding-top: 32px;
    padding-left: 32px;
    display: flex;
    align-items: center;
    position: relative;

    @media (min-width: 768px) {
      display: none;
    }

    .op-button {
      background-color: var(--colorUtnButton) !important;
      height: 36px !important;
      color: var(--colorTextBase) !important;
      border: none;

      &:hover {
        opacity: 60%;
      }
    }
  }

  &__logo {
    display: flex !important;
    align-items: center !important; // Vertically center
    justify-content: center !important; // Horizontally center
    width: 100% !important;
    height: 80px !important; // Reduce height to fit everything
    padding: 8px 8px 0; // Reduce padding for better spacing
    margin-top: 6px;
    margin-bottom: 6px;
    flex-direction: column; // Stack logo and text vertically

    img,
    svg {
      max-width: 60%; // Reduce size of logo
      max-height: 50px; // Ensure it does not overflow
      object-fit: contain;
    }

    &--clickable {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__branding-text {
    font-size: 12px; // Reduce text size
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
  }


  &__sider {
    width: 104px;
    background-color: var(--colorHeaderBg) !important;
    z-index: 10001;
    height: 100%;
    flex: unset !important;
    display: flex;
    flex-direction: column;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      height: 100%;

      // 추가: 외부 컨테이너 (그림자 고정용)
      .op-nav-menu-container {
        position: relative; // 그림자의 기준점
        max-height: calc(100vh - 180px);
        flex: 1;
        padding: 8px;

        // 모바일에서 닫기 버튼이 있을 때
        @include common.smaller-than(common.$tablet-portrait-width - 1) {
          max-height: calc(100vh - 230px) !important; // 강제 적용
        }

        // 그림자 스타일
        &::before,
        &::after {
          content: "";
          position: absolute; // 컨테이너에 고정
          left: 0;
          width: 100%;
          height: 20px;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          z-index: 1;
        }

        &::before {
          top: 0;
          background: linear-gradient(to bottom, var(--controlTmpOutline), rgba(0, 0, 0, 0));
        }

        &::after {
          bottom: 0;
          background: linear-gradient(to top, var(--controlTmpOutline), rgba(0, 0, 0, 0));
        }

        // 스크롤 상태에 따른 그림자 표시
        &.scrolling-up::before {
          opacity: 1;
        }

        &.scrolling-down::after {
          opacity: 1;
        }
      }

      // 추가: 내부 스크롤 영역
      .op-nav-menu-scroll {
        overflow-y: auto;
        max-height: calc(100vh - 190px);
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden; // 가로 스크롤 방지

        // 모바일에서 닫기 버튼이 있을 때
        @include common.smaller-than(common.$tablet-portrait-width - 1) {
          max-height: calc(100vh - 240px) !important; // 강제 적용
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  // Footer now inside flex container
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 104px;
    height: 80px;
    padding: 5px 10px; // Minimal padding
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: var(--colorTextTertiary);
    background: var(--colorHeaderBg); // Optional for visibility
    z-index: 2;
    font-size: 12px;
  }

}